import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "services/ApiService";

export const initialState = {
  airports: [],
  loading: false,
  message: "",
  showMessage: false,
  totalPages: 1,
  totalRecords: 0,
  pageSize: 10,
};

export const fetchAirports = createAsyncThunk(
  "airport/fetchAirports",
  async (params) => {
    try {
      const { header, data } = await ApiService.getAirports(params);
      return {
        airports: data.airports,
        totalPages: header.get("x-acw-total-pages"),
        totalRecords: header.get("x-acw-total-records"),
      };
    } catch (err) {}
  }
);

export const searchAirports = createAsyncThunk(
  "airport/searchAirports",
  async (params) => {
    try {
      const { header, data } = await ApiService.searchAirports(params);
      return {
        airports: data.airports,
        totalPages: header.get("x-acw-total-pages"),
        totalRecords: header.get("x-acw-total-records"),
      };
    } catch (err) {}
  }
);

export const airportSlice = createSlice({
  name: "airport",
  initialState,
  reducers: {
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    // resetPaginationParams: (state) => {
    //   state.currentPage = initialState.currentPage;
    //   state.pageSize = initialState.pageSize;
    //   state.totalRecords = initialState.totalRecords;
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAirports.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAirports.fulfilled, (state, action) => {
        state.loading = false;
        state.airports = action.payload.airports;
        state.totalRecords = action.payload.totalRecords;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchAirports.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })
      .addCase(searchAirports.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchAirports.fulfilled, (state, action) => {
        state.loading = false;
        state.airports = action.payload.airports;
        state.totalRecords = action.payload.totalRecords;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(searchAirports.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      });
  },
});

export default airportSlice.reducer;

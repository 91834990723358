import { jwtDecode } from "jwt-decode";
import { createSlice } from "@reduxjs/toolkit";
import { signIn, signOut } from "./authSlice";

export const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  loading: false,
  message: "",
  showMessage: false,
  userId: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      const { email, firstName, lastName, userId } = action.payload;
      state.email = email;
      state.firstName = firstName;
      state.lastName = lastName;
      state.userId = userId;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.fulfilled, (state, action) => {
        const decodedToken = jwtDecode(action.payload);
        const { sub, firstName, lastName, userId } = decodedToken;
        state.email = sub;
        state.firstName = firstName;
        state.lastName = lastName;
        state.userId = userId;
      })
      .addCase(signOut.fulfilled, (state) => {
        const { email, firstName, lastName, userId } = initialState;
        state.email = email;
        state.firstName = firstName;
        state.lastName = lastName;
        state.userId = userId;
      });
  },
});

export const { setUserDetails } = userSlice.actions;

export default userSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "services/ApiService";

export const initialState = {
  menus: [],
  loading: false,
  message: "",
  showMessage: false,
  totalPages: 1,
  totalRecords: 0,
  pageSize: 10,
};

export const fetchMenus = createAsyncThunk(
  "menu/fetchMenus",
  async (params) => {
    try {
      const { header, data } = await ApiService.getMenus(params);
      return {
        menus: data.items,
        totalPages: header.get("x-acw-total-pages"),
        totalRecords: header.get("x-acw-total-records"),
      };
    } catch (err) {}
  }
);

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenus.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMenus.fulfilled, (state, action) => {
        state.loading = false;
        state.menus = action.payload.menus;
        state.totalRecords = action.payload.totalRecords;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchMenus.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      });
  },
});

export default menuSlice.reducer;

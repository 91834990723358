import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "services/ApiService";

export const initialState = {
  invoices: [],
  loading: false,
  message: "",
  showMessage: false,
};

export const fetchInvoices = createAsyncThunk("invoice/fetchInvoices", async (data) => {
  try {
    const response = ApiService.getInvoices(data);
    return response.data;
  } catch (err) {}
});

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoices.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        state.loading = false;
        state.invoices = action.payload;
      })
      .addCase(fetchInvoices.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      });
  },
});

export default invoiceSlice.reducer;

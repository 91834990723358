import { get, http } from "./HttpService";
import {
  ORDERS_INDEX,
  SEARCH_INDEX_API_KEY,
  SEARCH_FACETS,
  PARTNER_API_BASE,
} from "../configs/AppConfig";

const ApiService = {};

ApiService.getAirports = async (params) => {
  const { currentPage, pageSize, ...restOfParams } = params;
  const response = await get("airports", {
    header: {
      page: currentPage,
      "per-page": pageSize,
    },
    restOfParams,
  });
  return response;
};

ApiService.searchAirports = async (params) => {
  const { key, value, currentPage, pageSize } = params;
  const response = await get("airports", {
    header: {
      page: currentPage,
      "per-page": pageSize,
    },
    params: {
      filterType: key,
      filterValue: value,
    },
  });
  return response;
};

ApiService.getAirportDetails = async (params) => {
  const { key, value, currentPage, pageSize } = params;
  const response = await get("fbos", {
    header: {
      page: currentPage,
      "per-page": pageSize,
    },
    params: {
      filterType: key,
      filterValue: value,
    },
  });
  return response;
};

ApiService.getMenus = async (params) => {
  const { currentPage, pageSize, partnerId } = params;
  const response = await get("menu", {
    header: {
      page: currentPage,
      "per-page": pageSize,
    },
    params: {
      partnerId: partnerId,
      requires: "externalId",
    },
  });
  return response;
};

ApiService.getOrders = async (params) => {
  const { currentPage, pageSize, search, token, filter, sortField, sortOrder } =
    params;
  let apiUrl = `${PARTNER_API_BASE}/orders?$count=true&${SEARCH_FACETS}`;

  if (search) apiUrl = `${apiUrl}&search=${search}`;
  if (filter) apiUrl = `${apiUrl}&$filter=${filter}`;

  if (sortField) {
    apiUrl = `${apiUrl}&$orderby=${sortField} ${sortOrder}`;
  } else {
    apiUrl = `${apiUrl}&$orderby=deliverydatetime desc`;
  }

  apiUrl = `${apiUrl}&$skip=${(currentPage - 1) * pageSize}&queryType=full`;

  return await http({
    url: apiUrl,
    method: "GET",
    header: {
      "api-key": SEARCH_INDEX_API_KEY,
      Authorization: `Bearer ${token}`,
    },
  });
};

ApiService.getInvoices = function (params) {
  const data = fetch({
    url: "/invoices",
    method: "get",
    params,
  });
  return data.invoices;
};

export default ApiService;

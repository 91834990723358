import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "terms-conditions",
    path: `${AUTH_PREFIX_PATH}/terms-conditions`,
    component: React.lazy(() =>
      import("views/auth-views/terms-conditions")
    ),
  }
  // {
  //   key: "login-callback",
  //   path: `${AUTH_PREFIX_PATH}/login-callback`,
  //   component: React.lazy(() =>
  //     import("views/auth-views/authentication/login-callback")
  //   ),
  // },
  // {
  //   key: "register",
  //   path: `${AUTH_PREFIX_PATH}/register`,
  //   component: React.lazy(() =>
  //     import("views/auth-views/authentication/register")
  //   ),
  // },
  // {
  //   key: "forgot-password",
  //   path: `${AUTH_PREFIX_PATH}/forgot-password`,
  //   component: React.lazy(() =>
  //     import("views/auth-views/authentication/forgot-password")
  //   ),
  // },
];

export const protectedRoutes = [
  // {
  //   key: "dashboard.default",
  //   path: `${APP_PREFIX_PATH}/dashboards/default`,
  //   component: React.lazy(() => import("views/app-views/dashboards/default")),
  // },
  {
    key: "orders",
    path: `${APP_PREFIX_PATH}/orders`,
    component: React.lazy(() => import("views/app-views/orders")),
  },
  {
    key: "orderDetails",
    path: `${APP_PREFIX_PATH}/order/details/:id`,
    component: React.lazy(() => import("views/app-views/pages/orderDetails")),
    breadcrumb: true
  },
  // {
  //   key: "invoices",
  //   path: `${APP_PREFIX_PATH}/invoices`,
  //   component: React.lazy(() => import("views/app-views/invoices")),
  // },
  // {
  //   key: "invoiceDetails",
  //   path: `${APP_PREFIX_PATH}/invoice/details/:id`,
  //   component: React.lazy(() => import("views/app-views/pages/invoiceDetails")),
  // },
  // {
  //   key: "airports",
  //   path: `${APP_PREFIX_PATH}/airports`,
  //   component: React.lazy(() => import("views/app-views/airports")),
  // },
  // {
  //   key: "airportDetails",
  //   path: `${APP_PREFIX_PATH}/airport/details/:id`,
  //   component: React.lazy(() => import("views/app-views/pages/airportDetails")),
  // },
  // {
  //   key: "menus",
  //   path: `${APP_PREFIX_PATH}/menus`,
  //   component: React.lazy(() => import("views/app-views/menus")),
  // },
  {
    key: "settings",
    path: `${APP_PREFIX_PATH}/settings/*`,
    component: React.lazy(() => import("views/app-views/settings")),
    breadcrumb: true
  },
  {
    key: "terms-conditions",
    path: `${APP_PREFIX_PATH}/terms-conditions`,
    component: React.lazy(() => import("views/app-views/terms-conditions")),
    breadcrumb: true
  },
  // {
  //   key: "users",
  //   path: `${APP_PREFIX_PATH}/users`,
  //   component: React.lazy(() => import("views/app-views/users")),
  // },
];

import { createSlice } from "@reduxjs/toolkit";
import { signOut } from "./authSlice";

export const initialState = {
  history: [],
  email: "",
};

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    addToHistory: (state, action) => {
      const { search, user } = action.payload;
      state.email = user;
      state.history = [...state.history, { value: search }];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOut.fulfilled, (state) => {
      return initialState;
    });
  },
});

export const { addToHistory } = historySlice.actions;

export default historySlice.reducer;

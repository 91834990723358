import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
  NAV_TYPE_TOP,
} from "constants/ThemeConstant";
import { env } from "../env";

export const APP_NAME = "Customer Portal";
export const COMPANY_NAME = "Air Culinaire Worldwide";
export const API_BASE_URL = env.REACT_APP_DATA_MANAGEMENT_PAPI_URL;
export const SHIPDAY_API_BASE_URL = env.SHIPDAY_API_BASE_URL;
export const SEARCH_INDEX_BASE_URL = env.REACT_APP_SEARCH_INDEX_BASE_URL;
export const ORDERS_INDEX = env.REACT_APP_ORDERS_INDEX;
export const SEARCH_INDEX_API_KEY = env.REACT_APP_SEARCH_INDEX_API_KEY;
export const SEARCH_FACETS = env.REACT_APP_SEARCH_FACETS;
export const PARTNER_API_BASE = env.REACT_APP_PARTNERS_PAPI;
export const PARTNER_API_CLIENT_ID = env.REACT_APP_OKTA_CLIENT_ID;
export const PARTNER_API_CLIENT_SECRET = env.REACT_APP_OKTA_CLIENT_SECRET;
export const DOCUMENT_BLOB_SHA = env.REACT_APP_DOCUMENT_SHA;
export const OKTA_SCOPES =
  "openid profile email permission approle groups customer_party_ids";
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = `/auth`;
export const REDIRECT_URL_KEY = "redirect";
// export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboards/default`;
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/orders`;
export const UNAUTHENTICATED_ENTRY = "/login";

export const THEME_CONFIG = {
  navCollapsed: true,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_TOP,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
  blankLayout: false,
};

import fetch from "auth/FetchInterceptor";
import { getToken, http } from "./HttpService";
import {
  ORDERS_INDEX,
  SEARCH_INDEX_API_KEY,
  SEARCH_FACETS,
  PARTNER_API_BASE,
} from "../configs/AppConfig";

const AuthService = {};

AuthService.login = async (params) => {
  return await getToken(params);
};

AuthService.register = function (data) {
  return fetch({
    url: "/auth/register",
    method: "post",
    data: data,
  });
};

AuthService.logout = async (params) => {
  const { token } = params;
  return await http({
    url: `${PARTNER_API_BASE}/logout`,
    method: "post",
    header: {
      Authorization: `Bearer ${token}`,
    },
  });
};

AuthService.loginInOAuth = function () {
  return fetch({
    url: "/auth/loginInOAuth",
    method: "post",
  });
};

export default AuthService;

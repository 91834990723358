import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "services/ApiService";
import { signOut } from "./authSlice";
import moment from "moment";

export const initialState = {
  orders: [],
  loading: false,
  message: "",
  showMessage: false,
  totalPages: 1,
  totalRecords: 0,
  pageSize: 50,
  airports: [],
  fbos: [],
  orderStatus: [],
  search: "",
  status: [],
  airport: { key: "All Airports", value: "All Airports" },
  fbo: "All FBO's",
  currentPage: 1,
  sortField: "",
  sortOrder: "",
  filter: "",
  startDate: moment().add(-7, "days"),
  endDate: moment().add(7, "days"),
};

export const fetchOrders = createAsyncThunk(
  "order/fetchOrders",
  async (data, { rejectWithValue, getState }) => {
    try {
      const response = await ApiService.getOrders(data);
      if (response.status === 200) {
        return {
          orders: response.data.value,
          airports: response.data["@search.facets"]["airport/icao"].map(
            ({ count, value }) => ({
              key: value,
              value: `${value} (${count})`,
            })
          ),
          fbos: response.data["@search.facets"]["fbo/name"].map(
            ({ value }) => value
          ),
          orderStatus: response.data["@search.facets"].orderStatus.map(
            (item) => item.value
          ),
          totalRecords: response.data["@odata.count"],
          totalPages: Math.floor(response.data["@odata.count"] / 50),
        };
      } else if (response.status === 401) {
        return rejectWithValue(response.data);
      } else return initialState;
    } catch (err) {}
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setSearchText: (state, action) => {
      state.search = action.payload;
    },
    setSelectedStatus: (state, action) => {
      state.status = action.payload;
    },
    setSelectedAirport: (state, action) => {
      state.airport = action.payload;
    },
    setSelectedFbo: (state, action) => {
      state.fbo = action.payload;
    },
    setSortField: (state, action) => {
      state.sortField = action.payload;
    },
    setSelectedPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setSortOrder: (state, action) => {
      state.sortOrder = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setDate: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        const {
          orders,
          airports,
          fbos,
          orderStatus,
          totalPages,
          totalRecords,
        } = action.payload;
        return {
          ...state,
          loading: false,
          orders,
          airports,
          fbos,
          orderStatus,
          totalPages,
          totalRecords,
        };
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          message: action.payload,
          showMessage: true,
        };
      })
      .addCase(signOut.fulfilled, (state) => {
        return initialState;
      });
  },
});

export const {
  setSearchText,
  setSelectedStatus,
  setSelectedAirport,
  setSelectedFbo,
  setSelectedPage,
  setSortField,
  setSortOrder,
  setFilter,
  setDate,
} = orderSlice.actions;

export default orderSlice.reducer;
